import {ApolloClient, ApolloLink, HttpLink} from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache';
import {createUploadLink} from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import * as Sentry from '@sentry/react';
import cachePolicies from "./CachePolicies";

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  let operationName = '';
  let responseStatus = 'None'
  if(operation){
    operationName = operation.operationName;
  }
  if(operation && operation.getContext() && operation.getContext().response) {
    responseStatus = operation.getContext().response.status
  }
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path, uuid }) => {
      Sentry.withScope(scope => {
        scope.setExtra("Message", message);
        if(uuid){
          scope.setExtra("HoneyBadger UUID", uuid)
        }
        scope.setExtra("Operation Name", operationName);
        scope.setExtra("Response Status", responseStatus)
        Sentry.captureException(new Error(`GraphQL error: ${operationName}`));
      });
    });
  } else if (networkError){
    Sentry.withScope(scope => {
      if (operation){
        scope.setExtra("Operation Name", operationName)
        scope.setExtra("Response Status", responseStatus)
      }
      Sentry.captureException(new Error(`[Network error]: ${networkError}`));
    });
  }
});

const httpLink = ApolloLink.split(
    (operation) => operation.getContext().hasUpload,
    createUploadLink({ uri: '/graphql-agent', credentials: 'same-origin' }),
    new HttpLink({ uri: '/graphql-agent', credentials: 'same-origin' }),
);

let authToken = document.getElementsByName('csrf-token');
authToken = authToken[0] ? authToken[0].content : null;

const middlewareLink = new ApolloLink((operation, forward) => {
  if (!operation.getContext().headers) {
    operation.setContext({
      headers: {
        'X-CSRF-Token': authToken
      }
    })
  }
  operation.setContext({
    credentials: 'same-origin'
  });
  return forward(operation);
});

const link = ApolloLink.from([middlewareLink, errorLink, httpLink]);
const cache = new InMemoryCache(cachePolicies);

export default new ApolloClient({
  link,
  cache,
  connectToDevTools: true
});
