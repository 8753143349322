// Naming convention should be something like
// (Page or Location or Project) - (Button name or Description of what is happening with the action)
//
// CamelCase for each chunk divided by dashes.
// ie, AgentDetail-CreateClient, NavBar-CreateMarketActivityAlert
// ClientDetail-UpdateEmail, SavedSearchMap-CreateSavedSearch
//
// Try to avoid terms like names of initiatives or projects
// that might go away in the future, terms that a employee
// hired 5 years in the future from now would not be
// able to understand. For example, "EGON" or "Seller Automation"

export const analytics = {
  listingDetail: {
    hideListing: 'listingDetail-HideListingClick',
    interestedListing: 'listingDetail-InterestedListingClick',
    undoInterestedListing: 'listingDetail-UndoInterestedListingClick',
    undoHideListing: 'listingDetail-UndoHideListingClick',
  },
  listingPreviewCard: {
    hideListing: 'listingPreviewCard-HideListingClick',
    interestedListing: 'listingPreviewCard-InterestedListingClick',
    undoInterestedListing: 'listingPreviewCard-UndoInterestedListingClick',
    undoHideListing: 'listingPreviewCard-UndoHideListingClick',
  },
  listingAlertDashboard: {
    massDisableModalConfirm: 'ListingAlertsDashboard-MassDisableAlertsModalConfirmClick',
    createAlert: 'ListingAlertsDashboard-CreateAlertClick',
    createTemplate: 'ListingAlertsDashboard-CreateTemplateClick',
  },
  listingAlertTemplates: {
    dashboardCreateTemplate: 'ListingAlertTemplateDashboard-CreateTemplateClick',
    createSavedSearchCreateTemplate: 'CreateSavedSearchPage-CreateTemplateClick',
    updateSavedSearchCreateTemplate: 'UpdateSavedSearchPage-CreateTemplateClick',
    createSavedSearchesFromTemplate:
      'SavedSearchTemplatesPage-CreateSavedSearchesFromTemplateClick',
    contactsPageCreateSavedSearchesFromTemplate:
      'ContactsPage-CreateSavedSearchesFromTemplateClick',
    contactProfilePageCreateSavedSearchFromTemplate:
      'ContactProfilePage-CreateSavedSearchFromTemplateClick',
  },
  marketAlertDashboard: {
    massDisableModalConfirm: 'MarketAlertsDashboard-MassDisableAlertsModalConfirmClick',
    createAlert: 'MarketAlertsDashboard-CreateAlertClick',
    createTemplate: 'MarketAlertsDashboard-CreateTemplateClick',
  },
  marketAlertTemplates: {
    dashboardCreateTemplate: 'MarketAlertTemplateDashboard-CreateTemplateClick',
    createMarketAlertCreateTemplate: 'CreateMarketAlertPage-CreateTemplateClick',
    createMarketAlertsFromTemplatePage: 'MarketAlertTemplateDashboard-CreateAlertClick',
    updateMarketAlertCreateTemplate: 'UpdateAlertTemplatePage-CreateTemplateClick',
    contactsPageCreateMarketAlertsFromTemplate: 'ContactsPage-CreateMarketAlertsFromTemplateClick',
    contactProfilePageCreateMarketAlertFromTemplate:
      'ContactProfilePage-CreateMarketAlertFromTemplateClick',
  },
  anywhereSignup: {
    completePurchase: 'AnywhereSignup-CompletePurchase',
  },
  agentDashboard: {
    viewNurturedContactPage: 'AgentDashboard-ViewNurtureContactsPageClick',
    nurturedContactsOverageBannerContactMemberSuccess:
      'AgentDashboardNurturedContactsOverageBanner-ContactMemberSuccess',
    nurturedContactsOverageBannerBillingPageLink:
      'AgentDashboardNurturedContactsOverageBanner-BillingPageLink',
  },
  agentSignup: {
    accountDetailsRendered: 'AgentSignup-AccountDetailsRendered',
    checkoutRendered: 'AgentSignup-CheckoutRendered',
    completePurchase: 'AgentSignup-CompletePurchase',
  },
  agentQuickstart: {
    createNewClient: 'AgentQuickstart-CreateClientClick',
    selectExistingClient: 'AgentQuickstart-SelectExistingClient',
  },
  nurturedContacts: {
    nurturedContactCheckoutContactMemberSuccess: 'NurturedContactPurchase-ContactMemberSuccess',
    nurturedContactCheckoutContactMemberSuccessRendered:
      'NurturedContactPurchase-ContactMemberSuccess',
    nurturedContactsPageOverageBannerContactMemberSuccess:
      'NurturedContactsPageOverageBanner-ContactMemberSuccess',
    nurturedContactsPageOverageBannerBillingPageLink:
      'NurturedContactsPageOverageBanner-BillingPageLink',
    nurturedContactsPageMassAutoEngage: 'NurturedContactsPage-MassAutoEngageClick',
    nurturedContactsPageMassArchiveModalConfirm:
      'NurturedContactsPage-MassAutoArchiveModalConfirmClick',
    nurturedContactsPageMassDisableAutoNurtureModalConfirm:
      'NurturedContactsPage-MassDisableAutoNurtureModalConfirmClick',
    nurturedContactsPageMassAddTagsModalSave: 'NurturedContactsPage-MassAddTagsModalSave',
    nurturedContactsPageUsageCardUpsellCta: 'NurturedContactsPage-UsageCardUpsellCta',
    nurturedContactsMassUnarchive: 'NurturedContactsPage-MassUnarchiveButtonClick',
    nurturedContactsPageLearnMoreAboutAutoNurture: 'NurturedContactsPage-LearnMoreAboutAutoNurture',
    nurturedContactsPageLearnMoreAboutNurturedContacts:
      'NurturedContactsPage-LearnMoreAboutNurturedContacts',
    automationsPageEnableAutoNurture: 'AutomationsPage-EnableAutoNurtureClick',
    automationsPageDisableAutoNurture: 'AutomationsPage-DisableAutoNurtureClick',
    automationsPageLearnMore: 'AutomationsPage-LearnMoreClick',
    automationsPageBackFillAwaitingNurtureContacts:
      'AutomationsPage-BackFillAwaitingNurtureContactsClick',
    managerToolsMassEnableAgentAutoEngage: 'ManagerToolsAgentsTable-MassEnableAutoEngage',
    // Billing - Purchasing NC Packs clicks
    beginNurturedContactPurchase: 'NurturedContactBillingPage-BeginCheckoutClick',
    setupNurturedContactBillingInfo: 'NurturedContactBillingPage-SetupBillingInfoClick',
    purchaseNurturedContacts: 'NurturedContactBillingPage-PurchaseClick',
    // Billing - Purchasing Nurtured Contact component renders (views)
    nurturedContactOrderConfirmationRendered:
      'NurturedContactBillingPage-OrderConfirmationRendered',
    nurturedContactOrderSuccessRendered: 'NurturedContactBillingPage-OrderSuccessRendered',
  },
  proPlusTrial: {
    trialBannerOptIn: 'ProPlusTrialBanner-OptInClick',
    trialBannerLearnMore: 'ProPlusTrialBanner-LearnMoreClick',
    autoNurtureTrialPage: 'AutoNurtureTrialPage-ProPlusTrialOptInClick',
    homeValueAlertsTrialPage: 'HomeValueAlertsTrialPage-ProPlusTrialOptInClick',
  },
  quickStart: {
    enableAutoNurture: 'QuickStartPage-EnableAutoNurtureClick',
    setUpIntegration: 'QuickStartPage-SetUpIntegrationClick',
  },
  contacts: {
    uploadFile: 'Contacts-UploadFile',
  },
  proPlusSidebar: {
    autoNurtureQuickstart: 'ProPlusSidebar-CompleteAutoNurtureSetupClick',
  },
  homeReport: {
    // Create HomeReport form actions
    createAndCustomize: 'CreateHVAForm-CreateAndCustomize',
    quickCreate: 'CreateHVAForm-QuickCreateWithDefaults',
    deleteFromCreateForm: 'CreateHVAForm-DeleteHVA',
    customizeEmails: 'CreateHVAForm-CustomizeEmails',
    saveAndComplete: 'CreateHVAForm-SaveAndComplete',
    clickSuggestedProperty: 'CreateHVAForm-ClickSuggestedProperty',
    // Edit HomeReport form actions
    sendAlertNow: 'EditHVAForm-SendAlertNow',
    deleteFromEditForm: 'EditHVAForm-DeleteHVA',
    disableFromEditForm: 'EditHVAForm-DisableHVA',
    enableFromEditForm: 'EditHVAForm-EnableHVA',
    update: 'EditHVAForm-Update',
    // Client HomeReport dashboard
    viewMoreListings: 'ClientHVADashboard-ViewMoreListingsCTA',
    // Agent app client details page
    clientDetailEnableHva: 'ClientDetailHvaTable-EnableHVA',
    clientDetailDisableHva: 'ClientDetailHvaTable-DisableHVA',
    // HVA Imports
    uploadFile: 'HVAImport-UploadFile',
    // Home Alert Defaults page
    updateDefaults: 'HVASettings-UpdateDefaults',
    // Agent HomeReport dashboard
    createAlert: 'AgentHVADashboard-CustomizeAlerts',
    createAlertViaMassImport: 'AgentHVADashboard-MassImport',
    dashboardSendInvite: 'AgentHVADashboard-SendInvites',
    dashboardShareClientLandingPageModalOpen: 'AgentHVADashboard-ShareClientLandingPageModalOpen',
    dashboardShareClientLandingPageLinkCopied: 'AgentHVADashboard-ShareClientLandingPageLinkCopied',
    dashboardPreviewClientLandingPage: 'AgentHVADashboard-PreviewClientLandingPage',
    upsellCta: 'AgentHvaDashboard-UpsellCTA',
    recentActivityBlankSlateCreateHva: 'AgentHvaDashboard-RecentActivityBlankSlateCreateHva',
    recentActivityBlankSlateInviteClients:
      'AgentHvaDashboard-RecentActivityBlankSlateInviteClients',
    deleteRejectedEmailsModalOpen: 'AgentHvaDashboard-DeleteRejectedEmailsModalOpen',
    deleteRejectedEmails: 'AgentHvaDashboard-DeleteRejectedEmails',
    enableHomeReports: 'AgentHvaDashboard-EnableHomeReports',
    disableHomeReports: 'AgentHvaDashboard-DisableHomeReports',
    enableAutomationModalOpen: 'AgentHvaDashboard-EnableAutomationModalOpen',
    enableAutomation: 'AgentHvaDashboard-EnableAutomation',
    automationCardIntegrationsLink: 'AgentHvaDashboard-AutomationCardIntegrationsLink',
    automationCardQuickCreateLink: 'AgentHvaDashboard-AutomationCardQuickCreateLink',
    viewAllClientActivity: 'AgentHvaDashboard-ViewAllClientActivity',
    mostActiveClientsContactClient: 'AgentHvaDashboard-MostActiveClientsContactClient',
    // Home Alert Send Invites page
    sendInvites: 'HVAInvites-SendInvites',
    inviteesTab: 'HVAInvites-inviteesTab',
    invitedTab: 'HVAInvites-invitedTab',
    previewInviteEmailTab: 'HVAInvites-previewInviteEmailTab',
    // Billing - Purchasing HVA Subscriptions clicks
    hvaSubscriptionsContactSales: 'HVASubscriptions-ContactSales',
    beginHvaPurchase: 'HVAPurchase-BeginCheckout',
    setupHvaBillingInfo: 'HVAPurchase-SetupBillingInfo',
    purchaseHva: 'HVAPurchase-Purchase',
    purchaseHvaCompleteMassImportClients: 'HVAPurchase-MassImportClients',
    purchaseHvaCompleteInviteClients: 'HVAPurchase-InviteClients',
    hvaCheckoutContactMemberSuccess: 'HvaPurchase-ContactMemberSuccess',
    // Billing - Purchasing HVA Subscription component renders (views)
    hvaOrderConfirmationRendered: 'HVAPurchase-OrderConfirmation',
    hvaOrderSuccessRendered: 'HVAPurchase-OrderSuccess',
    hvaCheckoutContactMemberSuccessRendered: 'HvaPurchase-ContactMemberSuccessModalOpened',
    // Suggestions
    suggestedHvaTab: 'HVASuggestions-suggestionsTab',
    processedSuggestionsHvaTab: 'HVASuggestions-processedSuggestionsTab',
    processSuggestions: 'HVASuggestions-processSuggestions', // Button to process all selected suggestions
    suggestedHvasEnableAutomationModalOpen: 'HVASuggestions-EnableAutomationModalOpen',
    suggestedHvasEnableAutomation: 'HVASuggestions-EnableAutomation',
    suggestedHvasAutomationCardIntegrationsLink: 'HVASuggestions-AutomationCardIntegrationsLink',
    // HVA Settings
    hvaSettingsAutomationQuickCreateLink: 'HVASettings-AutomationQuickCreateLink',
    hvaSettingsEnableAutomationModalOpen: 'HVASettings-EnableAutomationModalOpen',
    hvaSettingsEnableAutomation: 'HVASettings-EnableAutomation',
    hvaSettingsDisableAutomation: 'HVASettings-DisableAutomation',
    // Listing Alert Widget - Address Update & HVA Create
    listingAlertAddressUpdate: 'ListingAlertWidget-UpdateAddress',
    listingAlertAddressSkip: 'ListingAlertWidget-SkipAddressUpdate',
    listingAlertHVACreate: 'ListingAlertWidget-CreateHVA',
    listingAlertHVASkip: 'ListingAlertWidget-SkipCreateHVA',
    listingAlertHVATryAgain: 'ListingAlertWidget-TryHVAManually',
  },
}
