export const mandatory = function mandatory() {
  const err = new Error('Missing parameter');
  throw err;
};

export default {
  password: (password) => {
    if (typeof password !== 'string') return returnError('You must enter a valid word');
    if (password.length < 6) return returnError('Your password must be at least 6 characters');
    return returnSuccess();
  },

  email: (email) => {
    const result = /^(?!mailto:|from:|to:|subject:)(.+@.+\..+)/i.test(email);
    if (!result) return returnError('Not a valid email address');
    return returnSuccess();
  },

  multipleEmails: function (emails) {
    const stringTest = this.nonEmptyInput(emails);
    if (stringTest.hasError) return stringTest;

    var emailsArr = emails.split(",").filter(Boolean);

    const length = emailsArr.length;
    for (var i = 0; i < length; i++) {
      const validEmail = this.email(emailsArr[i]);
      if (validEmail.hasError) return validEmail;
    }

    return returnSuccess();
  },

  matchingPasswords: function (pass, passConf) {
    //const validPassword = this.password(pass);
    //if (validPassword.error) return returnError(validPassword.reason);
    const validPasswordConfirmation = this.password(passConf);
    if (validPasswordConfirmation.hasError) return returnError(validPasswordConfirmation.reason);

    if (this.password(passConf).error) return false;
    if (pass !== passConf) return returnError('The passwords must be the same');
    return returnSuccess();
  },

  nonEmptyInput: (input) => {
    if (typeof input !== 'string') return returnError('You must enter a valid word');
    if (input === '') return returnError('You must enter a valid word');
    return returnSuccess();
  },

  clientForm: function (form, validatePassword) {
    let validations = {
      firstName: this.nonEmptyInput(form.firstName),
      lastName: this.nonEmptyInput(form.lastName),
      email: this.email(form.email)
    };

    if(validatePassword){
      validations.password = this.password(form.password);
    }

    return validations;
  }
}


function returnError(reason) {
  return {
    hasError: true,
    reason: reason
  }
}

function returnSuccess(reason) {
  return {
    hasError: false,
    reason: reason
  }
}
